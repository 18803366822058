<template>
  <div class="page-container">
    <div class="page-title">Пазлы</div>
    <div class="page-inner game-page-inner">
      <div class="game-detail-top">
        <router-link class="go-back" to="/">{{ $t("nav.back") }}</router-link>
      </div>

      <div class="puzzle-top">
<!--        <div class="puzzle-top-text">-->
<!--          Собери пазл первым из&nbsp;20&nbsp;удачливых игроков и&nbsp;получи фирменные носки от&nbsp;билайна! А&nbsp;если не&nbsp;успел, не&nbsp;расстраивайся&nbsp;&mdash; мы&nbsp;дарим 1000 кристаллов каждому, кто справится с&nbsp;заданием. Погрузись в&nbsp;мир головоломок и&nbsp;выиграй призы!-->
<!--        </div>-->

        <puzzleProgress :puzzle="userInfo.puzzle" :class="{completed : finished}"></puzzleProgress>
      </div>

      <puzzleGrid :puzzle="userInfo.puzzle" v-if="userInfo.puzzle"></puzzleGrid>

      <div class="bottom-buttons">
          <button class="btn btn-white" :class="{'disabled' : !finished}" @click="getPrize()">Открыть приз</button>
          <button class="btn btn-primary" @click="rulesPopup = true;">Правила игры</button>
      </div>

      <Modal
          v-model="rulesPopup"
          @close="modalClose"
          :title="'Правила'"
          class="modal-wrapper text-modal"
      >

        <div class="rules-content">
          <img src="@/assets/img/diamond.svg" width=100 alt="1000 кристаллов">
          <p>Собери пазл и получи 1000 кристаллов!</p>
          <p>Погрузись в мир головоломок и получай награду за свои достижения.</p>
        </div>

      </Modal>

      <Modal
          v-model="prizePopup"
          @close="modalClose"
          @confirm="modalClose"
          :title="$t('common.dailyReward.congrats')"
      >

        <div v-if="puzzle.type=='diamonds'">
          <div class="buy-popup-text" v-html='$t("common.win")'></div>

          <div class="buy-popup-reward">
            <div class="buy-popup-reward-title">{{ puzzle.value }} Кристаллов</div>
            <div class="buy-popup-reward-img">
              <img src="@/assets/img/crystall-popup.svg" alt="" />
            </div>
          </div>
        </div>

        <div v-if="puzzle.type==='promocode'" class="prize-promo">
          <div class="buy-popup-text" v-html='$t("common.win")'></div>
          <div class="prize-promo-content">
            <div class="prize-promo-images">
              <img :src="puzzle.value.prizeUrl" alt="" />
            </div>
            <div class="prize-promo-text-container">
              <div class="prize-promo-text-large" v-html="puzzle.value.promocode.description_short"></div>
              <div class="prize-promo-text-medium" v-html="puzzle.value.promocode.description"></div>
              <div class="prize-promo-text" v-html="puzzle.value.promocode.instruction"></div>
            </div>
          </div>
        </div>


        <template #customFooter>
          <div v-if="prizeType==='promocode'">
            <div class="promo-item-bottom bottom-prize">
              <Code :code="puzzle.value.promocode.value" :toastSuccess="$t('common.copySuccess')" v-if="puzzle.value.promocode.value"></Code>
              <a :href="puzzle.value.promocode.url" target="_blank" class="promo-item-link btn btn-primary">{{ $t("pages.promo.activate") }}</a>
            </div>
          </div>
          <div v-if="prizeType==='diamonds'">
            <div class="promo-item-bottom">
              <div @click="modalClose" class="btn btn-primary btn-wide">{{ $t('common.dailyReward.take') }}</div>
            </div>
          </div>
        </template>

      </Modal>

    </div>
  </div>
</template>

<script>
import { RouterLink, RouterView } from 'vue-router'
import { useUserStore } from '@/store/user';
import { mapActions, mapState } from 'pinia';
import puzzleProgress from '@/components/puzzle/progress.vue';
import puzzleGrid from '@/components/puzzle/grid.vue';
import Code from '@/components/promo/codeBlock.vue'
import Modal from '@/components/modals/Modal.vue'

export default {
  name: 'Nav',
  components: {
    RouterLink,
    RouterView,
    puzzleProgress,
    puzzleGrid,
    Modal,
    Code
  },
  data() {
    return {
      progress: 0,
      rulesPopup: false,
      prizePopup: false,
      puzzleObj: {},
      prizeType: null
    };
  },
  computed: {
    ...mapState(useUserStore, ['puzzle','userInfo']),
    puzzle (){
      if(this.userInfo.puzzle.prize){
        if (this.userInfo.puzzle.prize.type == 'promocode'){
          this.prizeType = 'promocode';
        }
        else if(this.userInfo.puzzle.prize.type == 'diamonds'){
          this.prizeType = 'diamonds';
        }
        return this.userInfo.puzzle.prize
      }
    },
    finished() {
      if (this.userInfo.puzzle.openedItems)
        return this.userInfo.puzzle.openedItems.length >= this.userInfo.puzzle.qty ? true : false
    }
  },
  methods: {
    modalClose() {
      this.rulesPopup = false;
      this.prizePopup = false;
    },
    getPrize() {
      this.prizePopup = true;
    }
  }
};
</script>
