<template>
  <div class="page-container">
    <div class="page-title">{{ $t("pages.main.pageTitle") }}</div>
    <div class="page-inner">
      <div class="main-top">
        <div class="main-announce" @click="announceModal = true">Призы от билайна и&nbsp;партнёров в&nbsp;розыгрыше <a href="#" class="main-announce">каждый день!</a></div>
        <div class="main-announce main-announce-small">Пройдите успешно 5&nbsp;уровней и&nbsp;получите награду&nbsp;&mdash; промокод для новых свершений!</div>
      </div>

      <puzzlePanel :info="userInfo"></puzzlePanel>
      <div class="main-games-grid">
        <div to="/sudoku" class="game-grid-item sudoku-item" @click="gameStart('sudoku')">
          <div class="game-title">Судоку</div>
          <div class="game-image-container"></div>
        </div>
        <div to="/fillword" class="game-grid-item fillwords-item" @click="gameStart('fillword')">
          <div class="game-title">Филворды</div>
          <div class="game-image-container"></div>
        </div>
        <div to="/nonogram" class="game-grid-item nonogram-item" @click="gameStart('nonogram')">
          <div class="game-title">Японские кроссворды</div>
          <div class="game-image-container"></div>
        </div>
      </div>

      <div class="main-reward-info">
        За любую пройденную игру
        <span class="main-reward-info-item">+<span class="main-reward-icon diamonds">2</span> кристалла</span> и&nbsp;до
        <span class="main-reward-info-item">+<span class="main-reward-icon points">35</span> очков</span>
      </div>

      <Modal
          v-model="announceModal"
          @close="modalClose"
          class="modal-wrapper info-modal"
      >
        <template #customHeader>
          <div class="info-modal-img"></div>
        </template>

        <div class="info-modal-content">
          <p>Призы от билайна и партнеров в розыгрыше каждый день!</p>
          <p>Пройдите как можно больше уровней и соберите все уникальные элементы пазла в течение месяца. Войдите в первую десятку собравших пазл и получите стакан из лимитированной коллекции билайна!</p>
        </div>

      </Modal>

    </div>
  </div>
</template>

<script>
import { RouterLink, RouterView } from 'vue-router'
import Modal from '@/components/modals/Modal.vue'
import puzzlePanel from '@/components/home/puzzlePanel.vue'
import { mapState, mapActions } from 'pinia';
import { useUserStore } from '@/store/user';

export default {
  name: 'Nav',
  components: {
    RouterLink,
    RouterView,
    Modal,
    puzzlePanel
    // ModalPreview
  },
  data() {
    return {
      announceModal: false,
    };
  },
  computed: {
    ...mapState(useUserStore, ['userInfo']),
  },
  methods: {
    ...mapActions(useUserStore, ['getUserInfo', 'openSuspendModal']),
    modalClose() {
      this.announceModal = false
    },
    gameStart(game) {
      if (this.userInfo.is_suspended == 1) {
        this.openSuspendModal();
      }
      else
        this.$router.push(`/${game}`)
    }
  }
};
</script>
