<template>
  <div class="page-container">
    <div class="page-title">{{ $t("pages.promo.pageTitle") }}</div>
    <div class="page-inner">

      <div class="shop-container">

        <div class="empty-list">Реклама, подробнее ниже</div>
        <div class="shop-list-section" v-if="promoList.length > 0">
          <promoItem v-for="item in promoList" :item="item"></promoItem>
        </div>
        <div class="empty-list" v-else>
          Промокодов еще нет
        </div>

      </div>

    </div>
  </div>
</template>

<script>
// import { promoList } from '@/promoList';
import promoItem from '@/components/promo/promoItem.vue';

import { useUserStore } from '@/store/user';
import { mapState, mapActions } from 'pinia';

export default {
  name: 'promoView',
  components: {
    promoItem
  },
  data() {
    return {
      // promoList: []
    };
  },
  async created() {
    await this.getPromo();
  },
  computed: {
    ...mapState(useUserStore, ['userInfo', 'promo']),
    promoList() {
      if (this.promo){
        return this.promo;
      }
    },
  },
  methods: {
    ...mapActions(useUserStore, ['getPromo']),
  }
};
</script>
