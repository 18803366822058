<template>
  <div class="page-container">
    <div class="page-title">Японские кроссворды</div>
    <div class="page-inner game-page-inner">
      <div class="game-detail-top">
        <router-link class="go-back" to="/">{{ $t("nav.back") }}</router-link>
        <div class="game-difficulty-title">{{ $t("pages.games.difficulty") }}</div>
      </div>

<!--      <difficulty :mode="'easy'"></difficulty>-->
      <div class="game-difficulty">
        <label class="difficulty-check">
          <input type="radio" v-model="gamesLevels.nonogramLevel" value="low" @change="levelChange($event)">
          <div class="difficulty-check-label">
            Легко
            <div class="difficulty-check-continue" v-if="userInfo.games.nonogram.levelName === 'low'">Продолжить</div>
          </div>
        </label>
        <label class="difficulty-check">
          <input type="radio" v-model="gamesLevels.nonogramLevel" value="middle" @change="levelChange($event)">
          <div class="difficulty-check-label">Средне
            <div class="difficulty-check-continue" v-if="userInfo.games.nonogram.levelName === 'middle'">Продолжить</div>
          </div>
        </label>
        <label class="difficulty-check">
          <input type="radio" v-model="gamesLevels.nonogramLevel" value="high" @change="levelChange($event)">
          <div class="difficulty-check-label">Сложно
            <div class="difficulty-check-continue" v-if="userInfo.games.nonogram.levelName ==='high'">Продолжить</div>
          </div>
        </label>
        <label class="difficulty-check">
          <input type="radio" v-model="gamesLevels.nonogramLevel" value="ultra" @change="levelChange($event)">
          <div class="difficulty-check-label">Экстремально
            <div class="difficulty-check-continue" v-if="userInfo.games.nonogram.levelName === 'ultra'">Продолжить</div>
          </div>
        </label>
      </div>

      <div class="game-preview game-grid-item" :class="$route.meta.gameType">
        <div class="game-title">Уровень №
          {{ currentLevel }}
        </div>
        <div :class="`game-image-container game-image-container-${gamesLevels.nonogramLevel}`">
          <!-- <div class="game-stats">
            <div class="game-stats-row">Партий сыграно: {{userInfo.games.nonogram.statsByLevels[`${gamesLevels.nonogramLevel}`]}}</div>
            <div class="game-stats-row">Партий отгадано: {{userInfo.games.nonogram.statsByLevels[`win_${gamesLevels.nonogramLevel}`]}}</div>
          </div> -->
        </div>
      </div>

      <div class="game-bottom-buttons">

        <div class="btn btn-white" @click="startNew()">Новая игра</div>
        <div class="btn btn-primary" @click="continueGame()" v-if="userInfo.games.nonogram.levelName === gamesLevels.nonogramLevel" :class="{ disabled : !userInfo.games.nonogram.hasActive }">Продолжить</div>

      </div>

      <Modal
          v-model="startModal"
          :title="'Уверены, что хотите начать новую игру?'"
          @close="confirmClose"
      >
        <div class="buy-popup-text">
          У вас есть сохраненная игра, которую можно продолжить.
        </div>

        <template #customFooter>
          <div class="modal-buttons-large">
            <div class="btn btn-default" @click="continueGame()" >Продолжить игру</div>
            <div class="btn btn-primary" @click="startGame()">Начать новую игру</div>
          </div>
        </template>

      </Modal>

      <Modal
          v-model="showNoGamesPopUp"
          :title="'Поздравляем!'"
          @close="toMainPage"
      >
        <div class="buy-popup-text">
          Вы играли слишком много, все доступные уровни закончились. Попробуйте другую игру
        </div>
        <div class="buy-popup-reward">
          <img src="@/assets/img/no-games.png" class="no-games-img" alt="fail" />
        </div>

        <template #customFooter>
          <div class="modal-buttons-large">
            <div @click="toMainPage" class="btn btn-primary">На главную</div>
          </div>
          <div class="updates-date">Новые задания будут в следующем месяце</div>
        </template>

      </Modal>

      <completedLevelModal :finishedLevel="finishedLevelCurrent" :level="gamesLevels.nonogramLevel" @closeCompletedLevelModal="closeCompletedLevelModal"></completedLevelModal>

    </div>
  </div>
</template>

<script>
import { RouterLink, RouterView } from 'vue-router'
import { useUserStore } from '@/store/user';
import { mapState, mapActions } from 'pinia';
import difficulty from '@/components/games/difficulty.vue'
import Modal from '@/components/modals/Modal.vue'
import completedLevelModal from '@/components/modals/completedLevelModal.vue'

export default {
  name: 'Nonogram',
  components: {
    RouterLink,
    RouterView,
    difficulty,
    Modal,
    completedLevelModal
  },
  data() {
    return {
      difficulty: 'low',
      noGames: false,
      startModal: false,
      finishedLevelPopUp: true,
      finishedTotalPopUp: true,
      finishedTotalPopUpContinue: false,
      gameLoad: false,
    };
  },
  computed: {
    ...mapState(useUserStore, ['userInfo','fillwordLevel','gamesLevels']),
    currentLevel(){
      let level = 0;
      if (this.userInfo && this.userInfo.games.nonogram){
          level = this.userInfo.games.nonogram.qtyCompleted;
          // if (!this.userInfo.games.fillword.hasActive){
            level +=1
          // }
      }
      return level
    },
    finishedLevelTotal() {
      if (this.userInfo.games.nonogram.statsByLevels) {
        const availableLevelTotal = this.userInfo.games.nonogram.statsByLevels.available;
        if ((availableLevelTotal<=0 && this.finishedTotalPopUp) || (availableLevelTotal<=1 && this.finishedTotalPopUpContinue)) {
          return true
        }
      }
      return false;
    },
    finishedLevelCurrent() {
      if (this.userInfo.games.nonogram.statsByLevels) {
        const gamesLevel = this.userInfo.games.nonogram.statsByLevels[this.gamesLevels.nonogramLevel];
        const availableLevel = this.userInfo.games.nonogram.statsByLevels[`total_available_${this.gamesLevels.nonogramLevel}`]
        const availableLevelTotal = this.userInfo.games.nonogram.statsByLevels.available;
        if (gamesLevel >= availableLevel && this.finishedLevelPopUp && availableLevelTotal>0) {
          return true;
        };
      }
      return false;
    },
    showNoGamesPopUp() {
      return this.noGames || this.finishedLevelTotal || this.LevelsBalanceEnd;
    },
    LevelsBalanceEnd() {
      if (this.userInfo.games.nonogram.statsByLevels) {
        return !this.userInfo.games.nonogram.hasActive  && this.userInfo.games.nonogram.statsByLevels.month_levels_balance <= 0;
      }
      return false;
    },
    LevelsBalanceEndIncludeActive() {
      if (this.userInfo.games.nonogram.statsByLevels) {
        return this.userInfo.games.nonogram.statsByLevels.month_levels_balance <= 0;
      }
      return true;
    },
  },
  watch: {
    userInfo: function (val) {
      if (val.is_suspended == 1){
        this.$router.push('/');
        this.openSuspendModal();
      }
    },
  },
  methods: {
    ...mapActions(useUserStore, ['nonogramGame', 'nonogramGameContinue', 'checkGame', 'changeLevel','levelTitle', 'openSuspendModal']),
    toMainPage() {
      setTimeout(() => {
        this.$router.push({name: `home`});
      }, 100);
      this.showNoGamesPopUp = false;
    },
    levelChange(event){
      this.changeLevel('nonogramLevel', event.target.value)
    },
    checkfinishedLevelTotal() {
      const maxAvailable = this.userInfo.games.nonogram.hasActive ? 1 : 0;
      return this.userInfo.games.nonogram.statsByLevels.available <= 0;
    },
    checkfinishedLevelCurrent() {
      const gamesLevel = this.userInfo.games.nonogram.statsByLevels[this.gamesLevels.nonogramLevel];
      const availableLevel = this.userInfo.games.nonogram.statsByLevels[`total_available_${this.gamesLevels.nonogramLevel}`]
      return gamesLevel >= availableLevel;
    },
    closeCompletedLevelModal() {
      this.finishedLevelPopUp = false;
    },
    startNew(){

      if (this.LevelsBalanceEndIncludeActive) {
        this.noGames = true;
      }

      if (this.userInfo.games.nonogram.hasActive){
        this.startModal = true;
      }
      else{
        this.startGame();
      }

    },
    async startGame(){
      if (this.gameLoad) {
        return false;
      }

      this.gameLoad = true;
      if (this.checkfinishedLevelTotal()) {
        this.finishedTotalPopUpContinue = true;
        this.startModal = false;
        return;
      }

      if (this.checkfinishedLevelCurrent()) {
        this.finishedLevelPopUp  = true;
        this.startModal = false;
        return;
      }

      localStorage.removeItem('nonogramCurrentState');
      localStorage.removeItem('rows');
      localStorage.removeItem('cols');
      let response = await this.nonogramGame({
        'level': this.userInfo.nonogramLevel
      });
      if (response){
        // this.disableSudokuErrors();
       if (response.redirect)
         window.location.href = (response.redirect);
       else
        this.$router.push({path: `/${this.$route.meta.gameType}/start`, params: { new: false } })
      }
      else{
        this.noGames = true;
      }
    },
    async continueGame(){
      if (this.gameLoad) {
        return false;
      }

      this.gameLoad = true;
      let response = await this.nonogramGameContinue()
      if (response){
        if (response.redirect)
          window.location.href = (response.redirect);
        else
          this.$router.push({path: `/${this.$route.meta.gameType}/start`, params: { new: true } })
      }
      else{
        this.noGames = true;
      }
    },
    confirmClose(){
      this.noGames = false;
      this.finishedTotalPopUp = false;
      this.startModal = false;
      this.finishedTotalPopUpContinue = false;
    }
  }
};
</script>
